// export const BACKEND_DOMAIN = 'http://localhost:5001'
// export const BACKEND_DOMAIN = 'https://ozdesign-middleware.undev.com.au'
export const BACKEND_DOMAIN = ''
const BASE_URL = `${BACKEND_DOMAIN}/v1`
export const POST_LOGIN = `${BASE_URL}/auth/login`
export const POST_REGISTER = `${BASE_URL}/auth/register`
export const POST_FORGOT_PASSWORD = `${BASE_URL}/auth/forgot-password`
export const POST_FORGOT_PASSWORD_VERIFY = `${BASE_URL}/auth/forgot-password/verify`
export const POST_RESET_PASSWORD = `${BASE_URL}/auth/reset-password`

export const POST_SYNC_CARTS = `${BASE_URL}/sync/carts`
export const POST_SYNC_PROMOTIONS = `${BASE_URL}/sync/promotions`
export const GET_LOGS = `${BASE_URL}/sync/logs`
export const POST_SYNC_STORES = `${BASE_URL}/sync/stores`
export const POST_SYNC_CUSTOMERS = `${BASE_URL}/sync/customers`
export const POST_SYNC_ORDERS = `${BASE_URL}/sync/orders`
export const POST_SYNC_QUOTES = `${BASE_URL}/sync/quotes`

export const SAVE_ACCESS_TOKEN = `${BASE_URL}/accessToken/save`
export const SAVE_CODE = `${BASE_URL}/accessToken/saveCode`
export const GET_CODE = `${BASE_URL}/accessToken/get`

// sync type
export const SYNC_CART_TYPE = 'SYNC_CART_TYPE'
export const SYNC_PRODUCT_TYPE = 'SYNC_PRODUCT_TYPE'
export const SYNC_PROMOTION_TYPE = 'SYNC_PROMOTION_TYPE'
export const SYNC_STORE_TYPE = 'SYNC_STORE_TYPE'
export const SYNC_CATEGORY_TYPE = 'SYNC_CATEGORY_TYPE'

// url feed
export const URL_FEED_CART = `${BACKEND_DOMAIN}/v1/sync/carts/all.csv`
export const URL_FEED_PRODUCT = `${BACKEND_DOMAIN}/v1/sync/products/all.csv`
export const URL_FEED_PROMOTION = `${BACKEND_DOMAIN}/v1/sync/promotions/all.csv`
export const URL_FEED_STORE = `${BACKEND_DOMAIN}/v1/sync/stores/all.csv`
export const URL_FEED_CONTACT = `${BACKEND_DOMAIN}/v1/sync/customers/all.csv`
export const URL_FEED_ORDER = `${BACKEND_DOMAIN}/v1/sync/orders/all.csv`
export const URL_FEED_QUOTE = `${BACKEND_DOMAIN}/v1/sync/quotes/all.csv`
export const URL_CUSTOMER_EXAMPLE_FEED = `${BACKEND_DOMAIN}/v1/customer/example.csv`
export const URL_PIM_TEMP_PRODUCTS_COUNT = `${BACKEND_DOMAIN}/v1/sync/pim/temp/total`

export const URL_ORDERHEADER_CSV = `${BACKEND_DOMAIN}/v1/sync/orders/all.csv?filename=orders`
export const URL_ORDERLINEITEMS_CSV = `${BACKEND_DOMAIN}/v1/sync/orders/all.csv?filename=orderlineitems`

// magento
export const GET_MAGENTO_BASE_URL = `${BACKEND_DOMAIN}/v1/sync/magento/base-url`

// cloudinary image
export const BASE_CLOUDINARY_IMAGE_URL = `${BACKEND_DOMAIN}/v1/image/cloudinary`
export const CLOUDINARY_IMAGE_SYNC = `${BASE_CLOUDINARY_IMAGE_URL}/sync`

// setting
export const BASE_SETTING_URL = `${BACKEND_DOMAIN}/v1/setting`
export const BASE_PRODUCT_FIELD_SETTING_URL = `${BASE_SETTING_URL}/product/fields`
export const PRODUCT_FIELD_CREATE = `${BASE_PRODUCT_FIELD_SETTING_URL}/create`
export const PRODUCT_FIELD_EDIT = `${BASE_PRODUCT_FIELD_SETTING_URL}/edit`
export const PRODUCT_FIELD_DELETE = `${BASE_PRODUCT_FIELD_SETTING_URL}/delete`
export const PRODUCT_FIELD_UPDATE_SORT = `${BASE_PRODUCT_FIELD_SETTING_URL}/sort-order`

// report
export const BASE_REPORT_URL = `${BACKEND_DOMAIN}/v1/report`
export const REPORT_ALL = `${BASE_REPORT_URL}/all`
export const REPORT_PIM_KEYS = `${BASE_REPORT_URL}/pim-keys`
export const REPORT_OPTIONS_KEYS = `${BASE_REPORT_URL}/options-keys`
export const REPORT_CREATE_URL = `${BASE_REPORT_URL}/post`

// queue
export const BASE_QUEUE_URL = `${BACKEND_DOMAIN}/v1/queue`
export const QUEUE_GET_BY_COMMAND = `${BASE_QUEUE_URL}/getByCommand`
export const QUEUE_POST = `${BASE_QUEUE_URL}/post`

// product range
export const BASE_RANGE_URL = `${BACKEND_DOMAIN}/v1/productRange`
export const GET_RANGES_URL = `${BACKEND_DOMAIN}/v1/productRange/all`
export const EXPORT_RANGES_URL = `${BACKEND_DOMAIN}/v1/productRange/export`

// google product type
export const BASE_GOOGLE_PRODUCT_TYPE_URL = `${BACKEND_DOMAIN}/v1/googleProductType`
export const GET_GOOGLE_PRODUCT_TYPES_URL = `${BACKEND_DOMAIN}/v1/googleProductType/all`
export const EXPORT_GOOGLE_PRODUCT_TYPES_URL = `${BACKEND_DOMAIN}/v1/googleProductType/export`

// user
export const BASE_USER_URL = `${BACKEND_DOMAIN}/v1/user`
export const ALL_USER_URL = `${BASE_USER_URL}/all`
export const CREATE_USER_URL = `${BASE_USER_URL}/create`
export const ME_URL = `${BASE_USER_URL}/me`

// feed
export const BASE_FEED_URL = `${BACKEND_DOMAIN}/v1/feed`
export const ALL_FEED_URL = `${BASE_FEED_URL}/all`
export const POST_FEED_URL = `${BASE_FEED_URL}/post`

// product rules
export const BASE_PRODUCT_RULE_URL = `${BACKEND_DOMAIN}/v1/product-rules`
export const PRODUCT_RULE_ALL = `${BASE_PRODUCT_RULE_URL}/all`
export const PRODUCT_RULE_POST_URL = `${BASE_PRODUCT_RULE_URL}/post`

// product
export const BASE_PRODUCT_URL = `${BACKEND_DOMAIN}/v1/app-product`
export const PRODUCT_ALL = `${BASE_PRODUCT_URL}/all`
export const PRODUCT_POST_URL = `${BASE_PRODUCT_URL}/post`
export const PIM_SAMPLE_CSV_URL = `${BASE_PRODUCT_URL}/sample.csv`

// category
export const BASE_CATEGORY_URL = `${BACKEND_DOMAIN}/v1/category`

// store
export const BASE_STORE_URL = `${BACKEND_DOMAIN}/v1/store`
export const STORE_SAMPLE_CSV_URL = `${BASE_STORE_URL}/sample.csv`

// headless config
export const BASE_HEADLESS_CONFIG_URL = `${BACKEND_DOMAIN}/v1/headless/config`
export const HEADLESS_CONFIG_GET = `${BASE_HEADLESS_CONFIG_URL}/get`
export const HEADLESS_CONFIG_POST = `${BASE_HEADLESS_CONFIG_URL}/post`

// search terms
export const BASE_SEARCH_TERM_URL = `${BACKEND_DOMAIN}/v1/search-term`
export const SEARCH_TERM_ALL = `${BASE_SEARCH_TERM_URL}/all`
export const SEARCH_TERM_POST_URL = `${BASE_SEARCH_TERM_URL}/post`
export const SEARCH_TERM_EXPORT_URL = `${BASE_SEARCH_TERM_URL}/export`

// seo pages
// product
export const BASE_SEO_URL = `${BACKEND_DOMAIN}/v1/seo`
export const SEO_GET = `${BASE_SEO_URL}/get`

// display tab
export const BASE_DISPLAY_TAB_URL = `${BACKEND_DOMAIN}/v1/display-tab`
export const DISPLAY_TAB_ALL = `${BASE_DISPLAY_TAB_URL}/all`
export const DISPLAY_TAB_POST_URL = `${BASE_DISPLAY_TAB_URL}/post`
export const DISPLAY_TAB_UPDATE_SORT_ORDER_URL = `${BASE_DISPLAY_TAB_URL}/sort-order`

// seo pages
// product
export const BASE_DELIVERY_URL = `${BACKEND_DOMAIN}/v1/delivery`
export const DELIVERY_GET = `${BASE_DELIVERY_URL}/get`
export const DELIVERY_UPLOAD = `${BASE_DELIVERY_URL}/upload`
